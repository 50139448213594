@import "bootstrap";

$primary: $blue-600;
$dark: $blue-800;
$secondary: $gray-200;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "dark": $dark,
  "success": $success,
  "info": $info,
  "warning": $warning,
);


@import "scss/lateralColumn.scss";
@import "scss/question.scss";
@import "scss/navBar.scss";
@import "scss/body.scss";
@import "scss/settings.scss";
@import "scss/evaluation.scss";
@import "scss/print.scss";

@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

body {
  position: relative;
}